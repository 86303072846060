import 'owl.carousel'
import 'bootstrap'
import AOS from 'aos';
import { CountUp } from 'countup.js'
import "magnific-popup";
import "public/flow/js/comparison_slider.js";

window.AOS = AOS;
// JavaScript
$(document).ready( function () {

  AOS.init({
    duration: 600,
    anchorPlacement: 'center-bottom'
  });

  // header
  $('.navbar-group .navbar-icon').click(function(){
    $('.nav-search .search-block').toggle(300);
  });
  $('.nav-search .search-block').mouseleave(function(){
    $('.nav-search .search-block').hide(800);
  })

  // index banner slider
  $(".main-slider").owlCarousel({
    navText : ["",""],
    rewindNav : true,
    loop: true,
    nav: false,
    dots: true,
    animateOut: 'fadeOut',
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 4000,
    items:1
  });

  //index counter, countup.js
  var notYet = true
  $(window).on('scroll',function(){
    if(notYet){
      var countUp = new CountUp('counter-resolution', 7, 3000, { enableScrollSpy: true });
      countUp.start();

      var countUp2 = new CountUp("counter-cost", 72, 5000, { enableScrollSpy: true });
      countUp2.start();

      var countUp3 = new CountUp("counter-sample", 30, 4000, { enableScrollSpy: true });
      countUp3.start();

      var countUp4 = new CountUp("counter-ai", 10, 3000, { enableScrollSpy: true });
      countUp4.start();
      notYet = false;
    }
  })

  // index product animation
  $('.index-product-card').mouseenter(function(){
    $(this).find('img.index-product-color').fadeIn(1000);
  })
  $('.index-product-card').mouseleave(function(){
    $(this).find('img.index-product-color').hide();
  })

  // index awards and partners slider,owlCarousel
  $(".index-awards-partners-slider .index-awards-slider").owlCarousel({
    navText : ["",""],
    rewindNav : true,
    loop: true,
    nav:true,
    dots: false,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 4000,
    items:5,
    responsive:{
      0:{
          items:2
      },
      400:{
          items:3
      },
      1000:{
          items:4
      },
      1200:{
          items:5
      }
    }
  });

  $(".index-awards-partners-slider .index-partners-slider").owlCarousel({
    navText : ["",""],
    rewindNav : true,
    loop: true,
    nav:true,
    dots: false,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 4000,
    items:5,
    responsive:{
      0:{
          items:2
      },
      400:{
          items:3
      },
      1000:{
          items:4
      },
      1200:{
          items:5
      }
    }
  });

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // particle_counter Page owl control
  $('#product-owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    dots: true,
  });
  $("#owl-carousel-pre-btn").click(function() {
    $('#product-owl-carousel').trigger('prev.owl.carousel');
  });
  $("#owl-carousel-next-btn").click(function() {
    $('#product-owl-carousel').trigger('next.owl.carousel');
  });

  // downloads magnificPopup
  $('.popup-video').magnificPopup({
    type: 'iframe'
  });

  // faq accordion
  $('.accordion button').on('click', function (){
    $(this).closest('.accordion').siblings().find('.collapse').removeClass('show');
    $(this).closest('.accordion').siblings().find('button').attr('aria-expanded','false');
  });

  // semi tooltip
  function tooltip() {
    $('[data-tooltip]').on('click', function () {
      $(this).toggleClass('active');
      $(this).find('.tooltip-content').fadeIn(200);
      $(this).siblings().removeClass('active');
      $(this).siblings().find('.tooltip-content').fadeOut(200);
    });
  }
  tooltip();
});

// loader
$(window).on("load", function () {
  $('[data-page=index]').find('.loader-container .loader-inner svg').addClass('active');
  setTimeout(function(){
    $('.loader-container').fadeOut(700);
  }, 2300);
});

$(window).on("resize", function() {
  setTimeout(() => {
    AOS.refresh();
  }, 3000);
});
